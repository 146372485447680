import React from "react"
import { Vk, Facebook, Instagram, FiveHundredPixels } from "./icons/"

function SocialLink(props) {
  const components = {
    vk: Vk,
    facebook: Facebook,
    instagram: Instagram,
    fivehundredpixels: FiveHundredPixels,
  }
  const CompName = components[props.name]
  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }
  return (
    <a href={props.href} className="social-link" target="blank">
      <CompName style={styles} className="social-link" />
    </a>
  )
}

export default SocialLink
