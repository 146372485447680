import React from 'react'
// import { Link } from 'gatsby'
import {StaticQuery, graphql} from 'gatsby'

import Layout from '../components/layout'
import Slider from '../components/frstPageSlider'
import PageText from '../components/firstPageText'
import SEO from "../components/seo"

const keywords = ['профессиональная фотосессия в москве',
'хайэнд ретушь',
'профессиональная ретушь портрета',
'high end ретушь',
'студийный фотограф в москве',
'журнальная ретушь',
'студийная фотосессия в москве',
'фотосессия в студии',
'фотограф в москве',
'лучший фотограф в москве',
'лучший ретушер',
'найти ретушера',
'премиум фотосессия',
'фотосессия на природе',
'фотосессия на улице',
'стрит съемка',
'подводная фотосессия',
'подводный фотограф',
'рекламная съемка',
'каталожная съемка',
'как выбрать фотографа',
'как найти фотографа',
'портретный фотограф москва',
'фотограф москва инстаграм',
'фотограф девушка',]


const IndexPage = () => (
  <StaticQuery
    query={graphql`
        query StartPage{
          wordpressPage(wordpress_id : {eq : 108}) {
            id
            acf{
              main_page_gallery{
                localFile{
                  childImageSharp{
                    original{
                      src
                    }
                    fluid (maxWidth :1024 quality:100){
                      src
                    }
                  }
                }
              }
              mainheader
              additionaltext
              vk_link
              facebook_link
              instagram_link
              wordpress_500px_link
            }
          }
          site {
              id
              siteMetadata {
                  title
              }
          }
        }`
    }
    
    render={ (data)=>{
        const images = data.wordpressPage.acf.main_page_gallery.map((item)=>{
          return item.localFile.childImageSharp.original.src
        })
        return(
          <Layout>
            <SEO title={data.site.siteMetadata.title} keywords={keywords} lang='ru_ru'/>
            <PageText
              mainheader = {data.wordpressPage.acf.mainheader}
              additionaltext = {data.wordpressPage.acf.additionaltext !== '' ? data.wordpressPage.acf.additionaltext : false}
              vk_link = {data.wordpressPage.acf.vk_link}
              facebook_link = {data.wordpressPage.acf.facebook_link}
              instagram_link = {data.wordpressPage.acf.instagram_link}
              wordpress_500px_link = {data.wordpressPage.acf.wordpress_500px_link}
            />
            <Slider images={images}/>
          </Layout>
        )
      }
    }
  />
)

export default IndexPage
