import React from "react"
import Sociallink from "./components/SocialLink"
import styled from "styled-components"
import "./style.css"

const SocialWrapper = styled.div`
  display: flex;
  color: #ffffff;
  text-align: center;
`

export default props => {
  return (
    <div className="main-page-body">
      <h1>{props.mainheader}</h1>
      {props.additionaltext ? (
        <p>{props.additionaltext}</p>
      ) : null}
      <div>
        <Sociallink href={props.vk_link} name="vk" />
        <Sociallink href={props.facebook_link} name="facebook" />
        <Sociallink
          href={props.wordpress_500px_link}
          name="fivehundredpixels"
        />
        <Sociallink href={props.instagram_link} name="instagram" />
      </div>
    </div>
  )
}
