import React, { Component } from "react"
import "./style.css"

const IDLE_TIME = 2000
const TRANSITION_TIME = 3000
const STEP_IDLE = 0
const STEP_CHANGE_FRONT = 1
const STEP_CHANGE_BACK = 2

class ImageSlider extends Component {
  constructor(props) {
    super(props)
    this.makeFrontImageChange = this.makeFrontImageChange.bind(this)
    this.makeBackImageToNext = this.makeBackImageToNext.bind(this)
    this.startToMove = this.startToMove.bind(this)
    this.timeouts = []
    this.state = {
      index: 1,
      step: STEP_IDLE,
      frontImage: props.images[0],
      backImage: props.images[1],
    }
  }
  componentDidMount() {
    this.startToMove()
  }
  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state

    if (prevState.step === STEP_IDLE && step === STEP_CHANGE_FRONT) {
      this.makeFrontImageChange()
    } else if (
      prevState.step === STEP_CHANGE_FRONT &&
      step === STEP_CHANGE_BACK
    ) {
      this.makeBackImageToNext()
    } else if (prevState.step === STEP_CHANGE_BACK && step === STEP_IDLE) {
      this.startToMove()
    }
  }
  startToMove() {
    const startTimer = setTimeout(() => {
      this.setState({
        step: STEP_CHANGE_FRONT,
      })
    }, IDLE_TIME)

    this.timeouts.push(startTimer)
  }
  makeFrontImageChange() {
    const { index, backImage } = this.state
    const { images } = this.props

    const slideTimer = setTimeout(() => {
      this.setState({
        step: STEP_CHANGE_BACK,
        index: index + 1 >= images.length ? 0 : index + 1,
        frontImage: backImage,
      })
    }, TRANSITION_TIME)

    this.timeouts.push(slideTimer)
  }
  makeBackImageToNext() {
    const { index } = this.state
    const { images } = this.props

    this.setState({
      step: STEP_IDLE,
      backImage: images[index],
    })
  }

  componentWillUnmount() {
    this.timeouts.forEach(clearTimeout)
  }

  render() {
    const { step, frontImage, backImage } = this.state
    return (
      <div
        className={"wrapper" + (step === STEP_CHANGE_FRONT ? " _move" : "")}
        style={{
          backgroundImage: `url(${backImage})`,
        }}
      >
        <div
          className="front-image"
          style={{
            backgroundImage: `url(${frontImage})`,
          }}
        />
      </div>
    )
  }
}

export default ImageSlider
